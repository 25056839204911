<script>
	export let title = '';
	export let text = '';
</script>

{#if title || text}
	<div class="overlay-text">
		<h1>{title}</h1>
		<p>{text}</p>
	</div>
{/if}

<style>
	.overlay-text {
		z-index: 1;
		padding: 1em;
		position: absolute;
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%),
			linear-gradient(to left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);

		backdrop-filter: blur(5px);
		width: 100%;
	}

	.overlay-text h1 {
		font-size: 2.5rem;
		font-weight: bold;
		color: #ffffff;
		text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
		margin-bottom: 0.5rem;
		line-height: 1.2;
	}

	.overlay-text p {
		font-size: 1rem;
		color: #ffffff;
		text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
		font-weight: 300;
		line-height: 1.5;
		letter-spacing: 1px;
	}

	@media (max-width: 767px) {
		.overlay-text h1 {
			font-size: 1.5rem;
		}

		.overlay-text p {
			font-size: 0.8rem;
		}
	}
</style>
